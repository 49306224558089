import { CoursesPageWithErrorModel } from '@features/courses';
import {
  CoursesPageContainer,
  getCoursesPageInitialProps,
} from '@features/courses/coursesPage';
import { wrapper } from '@store/store-v2';
import React, { ReactElement } from 'react';

export const CoursesPage = (props: CoursesPageWithErrorModel): ReactElement => (
  <CoursesPageContainer {...props} />
);

CoursesPage.getInitialProps = wrapper.getInitialPageProps(
  (store) => async (context) => getCoursesPageInitialProps(store, context)
);

export default CoursesPage;
